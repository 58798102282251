import React from "react"

import LandingBio from "../components/landing-bio"
import SEO from "../components/seo"
import NoHeaderLayout from "../components/noheader-layout"

const IndexPage = () => (
  <NoHeaderLayout>
    <SEO title="Home" keywords={[`jeroen van hautte`, `nlp`, `ai`]} />
    <LandingBio />
  </NoHeaderLayout>
)

export default IndexPage
